import { CardWithImage } from "../components/CardWithImage";
import { CardWithLogo } from "../components/CardWithLogo";
import { CardWithQR } from "../components/CardWithQR";
export const HomepPage = () => {
  return (
    <>
      <div className="flex w-full items-stretch">
        <CardWithLogo />
        <CardWithImage />
      </div>
      <div>
        <CardWithQR />
      </div>
    </>
  );
};
