import React from "react";
import { HomepPage } from "./pages/HomepPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { About } from "./pages/About";
import { FooterCard } from "./components/FooterCard";
import { TermsOfUse } from "./pages/TermsOfUse";
import { ContactUs } from "./pages/ContactUs";
import { Privacy } from "./pages/Privacy";
import { RefundAndCancellation } from "./pages/RefundAndCancellation";

export const AppContent = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomepPage />,
    },
    {
      path: "/about-us",
      element: <About />,
    },
    {
      path: "/terms-of-use",
      element: <TermsOfUse />,
    },
    {
      path: "/contact-us",
      element: <ContactUs />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/refund-and-cancellation",
      element: <RefundAndCancellation />,
    },
  ]);
  return (
    <div className="bg-background p-2">
      <RouterProvider router={router} />
      <FooterCard />
    </div>
  );
};
