import { Card } from "../generic/Card";

export const CardWithQR = () => {
  return (
    <Card bg="bg-cardBg" classes="max-sm:bg-white mt-2">
      <div
        className="flex flex-col justify-center items-center font-MabryPro"
        id="qr-section"
      >
        <h1 className="text-6xl font-bold py-24 max-sm:py-12 max-sm:text-center max-sm:text-4xl">
          Scan to Download Venn
        </h1>
        <img
          className="object-contain w-[400px] max-sm:w-[320px]"
          src="/image-with-qr.png"
        />
      </div>
    </Card>
  );
};
