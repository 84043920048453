import { Card } from "../generic/Card";
import { scrollToQr } from "../utils/scrollToQr";
import { LogoComponent } from "../generic/LogoComponent";
import { useEffect, useState } from "react";

export const CardWithLogo = () => {
  const [qrSection, setQrSection] = useState<HTMLElement | null>(null);

  const images = ["/final1.png", "/final2.png", "/final3.png", "/final4.png"];
  useEffect(() => {
    const qrSection = document.getElementById("qr-section");
    setQrSection(qrSection);
  });
  return (
    <Card bg="bg-cardBg" classes="max-sm:h-full">
      <div className="px-16 pt-10 max-sm:px-6 max-sm:pt-7 h-full font-MabryPro">
        <LogoComponent />
        <div className="flex flex-col md:h-full justify-center max-sm:items-center">
          <h1 className="text-6xl font-bold pr-8 leading-tight max-sm:text-4xl max-sm:pr-2 max-sm:pt-16">
            A daily dispatch of what's happening in Bangalore
          </h1>
          <div className="flex w-[90%] mt-8 mb-9 text-sm max-sm:flex-col max-sm:my-5 max-sm:w-[100%]">
            <a
              className="uppercase border border-black rounded-full bg-white px-4 py-2 text-center md:mr-2 max-sm:text-xs"
              href="https://events.venn.buzz/"
              target="_blank"
            >
              Check the daily roundup
            </a>
            <a
              className="cursor-pointer uppercase border border-black rounded-full px-4 py-2 text-center md:ml-2 max-sm:mt-2 max-sm:text-xs"
              onClick={() => (qrSection ? scrollToQr(qrSection) : null)}
            >
              download the app
            </a>
          </div>

          <div className="flex items-center max-sm:mt-2 max-sm:pb-16">
            <div className="flex ml-3">
              {images.map((image, index) => (
                <img
                  key={index}
                  className="-ml-3 w-[36px] max-sm:w-[32px]"
                  src={image}
                />
              ))}
            </div>
            <p className="ml-4 font-light max-sm:ml-2 max-sm:text-xs">
              A community of 25,000+ Bangloreans
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};
