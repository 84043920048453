import { Card } from "../generic/Card";
import { LogoComponent } from "../generic/LogoComponent";

export const RefundAndCancellation = () => {
  return (
    <Card bg="bg-cardBg" classes="max-sm:h-full">
      <div className="font-MabryPro py-14 px-12 text-justify">
        <LogoComponent />
        <h1 className="text-4xl font-bold pt-10 max-sm:text-2xl max-sm:pt-9">
          Refund and Cancellation
        </h1>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The refunds and cancellation policy that governs the end users’
          (“You/Your/User”) access to and use of ‘Venn Platform’ (which term
          shall be deemed to include the internet and mobile website
          https://events.venn.buzz/ as well as its mobile application and any
          other means to access the same from time to time) and the services
          offered thereunder, is published in accordance with the provisions of
          Rule 3 (1) of the Information Technology (Intermediaries guidelines)
          Rules, 2011.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The Venn Platform is owned and controlled by Putting Scene Ventures
          Private Limited, a company incorporated under the Companies Act, 2013
          with its registered office at 235, 1st A Main Rd, Stage 3,
          Indiranagar, Bengaluru, Karnataka 560038 (hereinafter referred to as
          "Venn/Us/Our/We"). Venn is allowing its registered users to avail
          certain Offers from authorised Restaurant Partners and individual
          artists, chefs, creators (referred as “Organisers”) onboarded by Venn
          by registering with its unique subscription-based content platform
          (“Services”).
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Venn owns and operates the website, https://events.venn.buzz/, the
          mobile and touch versions and any Venn Platforms we have now or in the
          future that reference the refund and cancellation policy.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          For the purpose of this Refunds and Cancellations policy, wherever the
          context so requires, "you" or “User” shall mean any natural or legal
          person who is offered by Venn to avail its Venn Services on the Venn
          Platform or by providing registration data while registering on the
          Venn Platform as a registered user using any computer systems.
        </p>
        <ul className="list-disc">
          <li className="text-md d pt-7">
            <b>For third party events listed on the Venn Platform: </b>For all
            events hosted on the platform for which ticketing is not managed by
            Venn, users will be redirected to a third party ticketing platform.
            All payments will be collected by the third party for purchases and
            Venn will not be liable for any refunds or cancellations. If the
            users wish to contact the organisers for any complaints, conflicts,
            refunds or cancellations they will have to reach out to the third
            party event organisers directly via the information available on the
            third party website.
          </li>
          <li className="text-md d pt-7">
            <b>For events listed on the platform by Venn Exclusive Partners:</b>
            For all events hosted on the platform for which ticketing is managed
            by Venn, users will be redirected to a Venn owned payment collection
            page where they will be able to make all purchases.The event details
            page will also contain details regarding the event refunds and
            cancellation policies as they may differ for every event.
            <ul className="list-outside">
              <li>
                For any refunds that happen due to payment failures, the payment
                gateway will be refunding the amount directly to the user as per
                the payment gateway guidelines. This information will be sent to
                the user on their registered email which they used while making
                the payment. For any queries regarding the pending refund, Venn
                will be able to provide the RRN number. To contact Venn for
                this, please write to us at
                <a
                  className=" underline"
                  href="mailto:Support@puttingscene.com"
                >
                  {" "}
                  support@puttingscene.com
                </a>
              </li>
              <li>
                For any cancellations that are requested post booking the event
                on Venn, users will have to abide by the Refunds and
                Cancellations policy mentioned on the event details page of the
                event they booked. If they wish to contact Venn for processing
                the cancellation, please write to us at{" "}
                <a
                  className=" underline"
                  href="mailto:Support@puttingscene.com"
                >
                  {" "}
                  support@puttingscene.com
                </a>{" "}
                in accordance with the timelines mentioned in cancellation
                policy of the event for the cancellation window
              </li>
              <li>
                For any further grievances to request a refund or cancellation,
                please write to us at support@puttingscene.com
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Card>
  );
};
