import { Card } from "../generic/Card";
import { LogoComponent } from "../generic/LogoComponent";

export const Privacy = () => {
  return (
    <Card bg="bg-cardBg" classes="max-sm:h-full">
      <div className="font-MabryPro min-h-[80vh] py-14 px-12 max-sm:py-7 max-sm:px-6 ">
        <LogoComponent />
        <h1 className="text-4xl font-bold pt-10 max-sm:text-2xl max-sm:pt-9">
          Privacy Policy
        </h1>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          This document is an electronic record in terms of Information
          Technology Act, 2000 and rules there under as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000.
          <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
            The privacy policy that governs the end users’ (“You/Your/User”)
            access to and use of ‘Venn Platform’ (which term shall be deemed to
            include the internet and mobile website https://events.venn.buzz/ as
            well as its mobile application and any other means to access the
            same from time to time) and the services offered thereunder, is
            published in accordance with the provisions of Rule 3 (1) of the
            Information Technology (Intermediaries guidelines) Rules, 2011. For
            further clarification the application “WhatsApp'' is the application
            provided by WhatsApp Inc.,1601 Willow Road, Menlo Park, California
            94025. Reference to the masculine gender includes reference to the
            feminine, neuter and vice versa. The Venn Platform is owned and
            controlled by Putting Scene Ventures Private Limited, a company
            incorporated under the Companies Act, 2013 with its registered
            office at 235, 1st A Main Rd, Stage 3, Indiranagar, Bengaluru,
            Karnataka 560038 (hereinafter referred to as "Venn/Us/Our/We"). Venn
            is allowing its registered users to avail certain Offers from
            authorised Restaurant Partners and individual artists, chefs,
            creators (referred as “Organisers”) onboarded by Venn by registering
            with its unique subscription-based content platform (“Services”).
          </p>
          <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
            Venn owns and operates the website, https://events.venn.buzz/, the
            mobile and touch versions and any Venn Platforms we have now or in
            the future that reference this Privacy Policy.
          </p>
          <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
            For the purpose of this Privacy Policy, wherever the context so
            requires, "you" or “User” shall mean any natural or legal person who
            is offered by Venn to avail its Venn Services on the Venn Platform
            or by providing registration data while registering on the Venn
            Platform as a registered user using any computer systems.
          </p>
          <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
            Please read this Policy before using the Venn Platform or submitting
            any personal information. This Policy is a part of and incorporated
            within, and is to be read along with, the Terms of Use.
          </p>
          <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
            By using the Venn Platform and the Services, you agree and consent
            to the collection, transfer, use, storage, disclosure and sharing of
            your information as described and collected by us in accordance with
            this Policy. If you do not agree with the Policy, please do not use
            or access the Venn Platform.
          </p>
          <ol>
            <li className="text-md d pt-7 px-7">
              I. General Provisions This Privacy Policy sets out policy
              regarding any personal information you might give to us via the
              website hosted at https://events.venn.buzz/ (“Website”) and
              related subdomains and application(s), and how that information
              may be used. May occasionally update this Policy and the changes
              thereafter will be posted on this page. Any changes to this Policy
              will be brought to your notice through the Venn Platform and/or
              registered e-mail. We may also take your consent again as and if
              required by applicable and relevant law. The user’s continued use
              of the Venn Platform after we publish and/or send a notice about
              the changes in Policy shall constitute the consent of the user
              with respect to the updated Policy.
            </li>
            <li className="text-md d pt-7 px-7">
              II. Collection of personal information May collect information or
              employ third party plugins that collect information about the
              devices the user uses to access our Services. Other details about
              the user will be obtained when the User registers with the Figgr
              Venn Platform. This type of information may include but shall not
              be limited to include information such as the User’s name, E-mail
              address, contact details, date of birth, credit card and/or
              payment instrument and any other information the user chooses to
              provide. Primarily collects the user’s information through written
              and electronic means of communication. If you login to our Venn
              Platform or register for an account or access our website via a
              third-party platform (For e.g., Facebook or Google), you consent
              to collect, store and use the information you make available to us
              via the third-party platform. This includes but is not limited to
              your name, date of birth, the profile information you make public
              (including your profile picture) and location information and any
              other information you may provide to us with your consent and
              during the subscription period. If you have questions and/or
              queries about how third-party integrations work, check the terms
              of use of the relevant third-party service. Information sent by
              your web browser may be automatically collected. This information
              typically includes but is not limited to the internet protocol
              (IP) address used to connect your computer or device to the
              Internet, operating system and platform, the average time spent on
              the Venn Platform, pages viewed, information searched for, access
              time, websites visited before, location information and other
              relevant statistics. The amount of information received and/or
              transmitted depends on the settings you have on your web browser
              and/or personal electronic device. Also uses the application of
              technologies to collect information, including cookies and plug-
              ins. Through the use of cookies, we may automatically collect
              information about your online activity on the Venn Platform, such
              as the web pages you visit, the links you click. In order to use
              the Venn Platform, you may be required to install certain
              plug-ins. Post registration you must consent to collection
              location services. We may collect and process information about
              your mobile device's GPS location (including the latitude or
              longitude of your mobile device) and the time the location
              information is recorded to customize the Services with
              location-based information and features. Some of these services
              require your personal data for the feature to work and we may
              associate location data with your device ID and other information
              we hold about you. We keep this data for no longer than is
              reasonably necessary for providing services to you. If you wish to
              use the particular feature, you will be asked to consent to your
              data being used for this purpose. You can withdraw your consent at
              any time by disabling the GPS or other location-tracking functions
              on your device, provided your device allows you to do this. If you
              withdraw your consent and/or disable services certain features of
              the platform may be restricted and/or not available to you. You
              may also choose to communicate anonymously or by using a
              pseudonym. By doing so you may be restricted to certain sections
              of the Venn Platform and it may affect ability to provide you
              solutions and queries with regards to products, services or
              benefits. This may also restrict and/ or prevent the processing of
              any payment made to .
            </li>
            <li className="text-md d pt-7 px-7">
              III. Use and disclosure of personal information By submitting your
              personal information, you consent to Venn using it to: Provide you
              with products and services (both our own and those of other
              entities); answer your queries regarding the products and services
              we provide, including support services; collection of payments
              and/or administer your account; provide you with updated or new
              information about products and services (both our own and those of
              other entities); monitor online activity on the Venn Platform;
              conduct competitions, deals and promotions; develop existing and
              new Venn by Putting Scene Venture Private Limited products and
              services; maintain and update our business infrastructure,
              databases and systems; promote our products and/or services and
              our suppliers' other products and/or services to you, including by
              way of direct marketing; and do any other act or thing required or
              authorized by law. Provide you with products and services (both
              our own and those of other entities); outsourced service providers
              and their personnel; third parties authorized by you to receive
              personal information held by us; our legal, accounting, financial
              or other professional advisors; regulatory, government and other
              authorities as required by law; and our related organizations. All
              information sent by your web browser will be used only to assist
              us for the purposes of providing an effective service on the Venn
              Platform. We may from time to time supply the owners or operators
              of third-party websites from which it is possible to link to the
              Venn Platform with information relating to the number of users
              linking to the Venn Platform from such third-party websites. You
              will not be personally identifiable from this information. Venn
              may use the information that it automatically receives from your
              web browser to see which pages you visit within the Venn Platform,
              which website you visited before coming to ours, and where you go
              after you leave. Venn can then develop statistics that are helpful
              in understanding how our visitors use the Venn Platform. We may
              use this information to measure the use of the Venn Platform and
              to administer and improve the Venn Platform. Once aggregated for
              statistical purposes, you will not be personally identified from
              this information. This statistical data is interpreted in its
              continuing effort to present the Venn Platform content that
              visitors are seeking in a format they find most helpful.
            </li>
            <li className="text-md d pt-7 px-7">
              IV. Changes to this privacy policy We reserve the right to amend
              this Privacy Policy from time to time to reflect changes in the
              law, our data collection and use practices, the features of our
              services, or advances in technology. Please check this page
              periodically for changes. Use of information we collect is subject
              to the Privacy Policy in effect at the time such information is
              used. If we make any material changes to this Privacy Policy, we
              will post the changes here. Please review the changes carefully.
              Your continued use of the Services following the posting of
              changes to this Privacy Policy will constitute your consent and
              acceptance of those changes.
            </li>
            <li className="text-md d pt-7 px-7">
              V. Third party links and services Your use of Venn’s services
              and/or features may result in the collection, processing or
              sharing of information about you with third party websites. Venn’s
              services and/or features may also contain third party links. Venn
              is not responsible for the content or privacy practices of other
              websites and/or services which may be linked to our services
              and/or features. Venn does not endorse or make any representations
              about third-party websites and/or services. Our Privacy Policy
              does not cover the information you choose to provide to or that is
              collected by these third parties. We emphatically encourage you to
              read such third parties' privacy policies.
            </li>
            <li className="text-md d pt-7 px-7">
              VI. Safety & Security Venn has enforced necessary physical,
              electronic, and managerial procedures to safeguard and help
              prevent unauthorized access to your information and to maintain
              data security. These safeguards take into account the sensitivity
              of the information that we collect, process and store. Venn
              follows accepted industry standards to protect the personal
              information submitted to us. The third-party service providers
              with respect to payment gateway and payment processing are all
              validated as compliant with the payment card industry standard and
              with the updated RBI guidelines. Venn assumes no liability or
              responsibility for disclosure of your information due to errors in
              transmission, unauthorized third-party access, or other causes
              beyond our control. You play an important role in keeping your
              personal information secure. You should not share your
              registration and/or login details, password, or other security
              information of your Venn account with anyone. If we receive
              instructions using your registration and/or login details and
              password, we will consider that you have authorized the
              instructions.
            </li>
            <li className="text-md d pt-7 px-7">
              VII. Contact Us If you have any queries relating to any the
              processing/ usage of information provided by you or our Privacy
              Policy, you may email us at support@puttingscene.com. You may also
              contact us on the above mentioned details if you wish to update
              and/or delete your existing details and/or information in our
              database and/or system.
            </li>
            <li className="text-md d pt-7 px-7">
              VII. Refund and Cancellation Policy No refunds or cancellations
              can be issued once the membership has been purchased. However, you
              can opt out of the service by writing us an email at
              support@puttingscene.com
            </li>
          </ol>
        </p>
      </div>
    </Card>
  );
};
