import { Card } from "../generic/Card";
import { LogoComponent } from "../generic/LogoComponent";

export const About = () => {
  return (
    <Card bg="bg-cardBg" classes="max-sm:h-full">
      <div className="font-MabryPro min-h-[80vh] py-14 px-12 max-sm:py-7 max-sm:px-6 ">
        <LogoComponent />
        <h1 className="text-4xl font-bold pt-10 max-sm:text-2xl max-sm:pt-9">
          About Us
        </h1>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          A Social Experiences platform rethinking how the tasteful & creative
          GenZs & Millennials hangout across metropolitan cities, globally
        </p>
      </div>
    </Card>
  );
};
