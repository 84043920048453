import { ReactElement } from "react";

export const Card = ({
  children,
  bg,
  classes,
  ...props
}: {
  children: ReactElement;
  bg: string;
  classes?: string;
}) => {
  return (
    <div
      className={`w-[99.5%] max-sm:w-[97%] max-sm:mx-auto ${bg} border border-black rounded-xl mx-1 ${classes}`}
      {...props}
    >
      {children}
    </div>
  );
};
