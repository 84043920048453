import React, { useEffect, useState } from "react";
import { Card } from "../generic/Card";
import { scrollToQr } from "../utils/scrollToQr";

export const CardWithImage = () => {
  const [qrSection, setQrSection] = useState<HTMLElement | null>(null);
  useEffect(() => {
    const qrSection = document.getElementById("qr-section");
    setQrSection(qrSection);
  });

  return (
    <Card bg="bg-white" classes="max-sm:hidden block ">
      <div className=" pt-10 justify-end rounded-[12px] w-[50vw] h-[90vh] relative overflow-hidden">
        <div className="px-16 flex justify-end font-MabryPro">
          <a href="mailto:Support@puttingscene.com">Support</a>
          <a
            className="pl-[72px] cursor-pointer"
            onClick={() => (qrSection ? scrollToQr(qrSection) : null)}
          >
            Download
          </a>
        </div>

        <img
          className="w-[100%] h-[100%] object-cover absolute right-2 m-auto z-0"
          src="/hero-image-3.png"
          alt="placeholder"
        />
      </div>
    </Card>
  );
};
