import { EnvelopeSimple } from "@phosphor-icons/react";
import { Card } from "../generic/Card";
import { LogoComponent } from "../generic/LogoComponent";

export const ContactUs = () => {
  return (
    <Card bg="bg-cardBg" classes="max-sm:h-full">
      <div className="font-MabryPro min-h-[80vh] py-14 px-12 max-sm:py-7 max-sm:px-6 ">
        <LogoComponent />
        <h1 className="text-4xl font-bold pt-10 max-sm:text-2xl max-sm:pt-9">
          Contact Us
        </h1>
        <a
          className="text-md d pt-7 flex items-center underline"
          href="mailto:Support@puttingscene.com"
        >
          <EnvelopeSimple size="22" className="mr-2" />
          support@puttingscene.com
        </a>
      </div>
    </Card>
  );
};
