import { Card } from "../generic/Card";
import { LogoComponent } from "../generic/LogoComponent";

export const TermsOfUse = () => {
  return (
    <Card bg="bg-cardBg" classes="max-sm:h-full">
      <div className="font-MabryPro min-h-[80vh] py-14 px-12 max-sm:py-7 max-sm:px-6 ">
        <LogoComponent />
        <h1 className="text-4xl font-bold pt-10 max-sm:text-2xl max-sm:pt-9">
          Terms & Conditions
        </h1>

        <h2 className="text-2xl font-bold max-sm:text-lg max-sm:pt-6">
          Acceptance of terms of use
        </h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          This document is an electronic record in terms of Information
          Technology Act, 2000 and rules there under as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The terms and conditions of use and privacy policy that governs the
          end users’ (“You/Your/User”) access to and use of ‘Venn Platform’
          (which term shall be deemed to include the internet and mobile website{" "}
          <a href="https://events.venn.buzz/">https://events.venn.buzz/</a> as
          well as its mobile application and any other means to access the same
          from time to time) and the services offered thereunder, is published
          in accordance with the provisions of Rule 3 (1) of the Information
          Technology (Intermediaries guidelines) Rules, 2011. For further
          clarification the application “WhatsApp'' is the application provided
          by WhatsApp Inc.,1601 Willow Road, Menlo Park, California 94025.
          Reference to the masculine gender includes reference to the feminine,
          neuter and vice versa. The Venn Platform is owned and controlled by
          Putting Scene Ventures Private Limited, a company incorporated under
          the Companies Act, 2013 with its registered office at 235, 1st A Main
          Rd, Stage 3, Indiranagar, Bengaluru, Karnataka 560038 (hereinafter
          referred to as "Venn/Us/Our/We"). Venn is allowing its registered
          users to avail certain Offers from authorised Restaurant Partners and
          individual artists, chefs, creators (referred as “Organisers”)
          onboarded by Venn by registering with its unique subscription-based
          content platform (“Services”).
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Venn owns and operates the website,{" "}
          <a href="https://events.venn.buzz/">https://events.venn.buzz/</a>, the
          mobile and touch versions and any Venn Platforms we have now or in the
          future that reference these Terms of Use.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          For the purpose of this Terms & Conditions, wherever the context so
          requires, "you" or “User” shall mean any natural or legal person who
          is offered by Venn to avail its Venn Services on the Venn Platform or
          by providing registration data while registering on the Venn Platform
          as a registered user using any computer systems.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          This T&C incorporates Venn's standard policies, procedures, and terms
          and conditions for use of the Services, including:
        </p>
        <ul className="list-disc">
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Privacy Policy as mentioned{" "}
            <a href="https://events.venn.buzz/privacy">here</a>;
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Disclaimer Notices
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            and any other policies of the Venn as may be referenced by name or
            by links in this T&C or otherwise detailed out in the Venn Platform
            (collectively, the Venn Policies).
          </li>
        </ul>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          By using Venn’s website and services through the Venn Platform and/or
          by Signing up for an account on Venn’s and/or by clicking accept or
          agree to this T&C:
        </p>
        <ul className="list-disc">
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            you acknowledge that you have read, understood, and agreed to be
            bound by this T&C;
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            you represent and warrant that you are of legal age and not
            prohibited by law from accessing or using the Services, including,
            if applicable, purchasing, giving, using, or redeeming any offers
            provided by Venn, which may include but not limited to any offers
            such as cash back, discount, free meal or beverage or sweet or gift,
            as per the discretion of Venn and the onboarded restaurants
            partners. (Offers); and
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            any additional terms applicable to certain programs in which you may
            elect to participate.
          </li>
        </ul>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Venn may update, amend or revise this T&C (including any Venn
          Policies) from time to time. You agree that you will review this T&C
          and other Venn Policies periodically. You are free to decide whether
          or not to accept a modified version of this T&C and other Venn
          Policies, but accepting this T&C and other Venn Policies, as modified,
          is required for you to continue using the Services. You may have to
          click accept or agree to show your acceptance of any modified version
          of this T&C. If you do not agree to the terms of this T&C or any
          modified version of this T&C, you must either not use such Services or
          terminate your use of the Services, in which case you will no longer
          have access to services. Except as otherwise expressly stated by Venn,
          any use of the Services or availing of certain Offers provided by Venn
          or the onboarded restaurants partners is subject to the version of the
          T&C in effect at the time of use.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          IF YOU DO NOT AGREE TO THESE TERMS OF USE, IMMEDIATELY STOP USING THE
          VENN PLATFORM AND DO NOT USE ANY Venn SERVICE, PARTICIPATE IN ANY
          PROGRAM OR PURCHASE ANY VOUCHER, PRODUCT OR OTHER GOOD OR SERVICE
          OFFERED THROUGH THE VENN PLATFORM.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          PLEASE REVIEW THE FOLLOWING SECTIONS OF THESE TERMS OF USE CAREFULLY:
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          These Terms of Use are organized as follows:
        </p>
        <ul className="list-disc">
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            About the Venn Platform
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Ownership of the Venn Platform
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Use of the Venn Platform
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Access to the Venn Platform
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Service Modification
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Your Account
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Your Conduct
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Your Privacy
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Copyright and Trademarks
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Hyperlinking to our Content
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Unsolicited Ideas
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Disclaimer of Warranty
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Limitation of Liability
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Electronic Communications
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Websites of Others
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Indemnification/Release
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Force Majeure
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Assignment
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Entire Agreement
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Cookies
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Governing Law and Dispute Resolution
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Disclaimers
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Additional Disclosures
          </li>
        </ul>
        <h2 className="text-xl font-bold pt-10">1. About the Venn Platform</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          This is a platform through which certain merchants (“Restaurant
          Partners”) (a) sell vouchers for goods, services, or experiences
          (“Vouchers”), (b) sell dining experiences for specific dates and times
          (" Reservations "), (c) sell food, beverage and other products for
          delivery and takeout (“Online Ordering”), (d) enable you to schedule
          use of your Voucher on a specific date and time (“Bookings”)
          (collectively (a)-(d), “Restaurant Partner Offerings”). Merchants are
          the sellers and issuers of the Merchant Offerings and are solely
          responsible to you for the care, quality, and delivery of the goods
          and services provided. Venn is not an agent of the Restaurant
          Partners.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          This is also platform through which certain individual artists, chefs
          and creators (“Organisers”) (a) sell vouchers for goods, services, or
          experiences (“Vouchers”), (b) sell dining experiences for specific
          dates and times ("Reservations "), (c) sell food, beverage and other
          products for delivery and takeout (“Online Ordering”), (d) enable you
          to schedule use of your Voucher on a specific date and time
          (“Bookings”) (collectively (a)-(d), “Organiser Offerings”). Organisers
          are the sellers and issuers of the Organiser Offerings and are solely
          responsible to you for the care, quality, and delivery of the goods
          and services provided. Venn is not an agent of the Organisers.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          In addition, the Venn Platform also provides a platform through which
          you can purchase products from Venn (“Products”) and participate in
          other available programs. Certain Merchant Offerings, Organiser
          Offerings, Products, other available programs and pricing on the Venn
          Platform may change at any time in Venn’s sole discretion, without
          notice.
        </p>
        <h2 className="text-xl font-bold pt-10">
          2. Ownership of the Venn Platform
        </h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The Venn Platform, any content on the website, and the infrastructure
          used to provide the Venn Platform are proprietary to us, our
          affiliates, Restaurant Partners, Organisers and other content
          providers. By using the Venn Platform and accepting these Terms of
          Use: (a) Venn grants you a limited, personal, non-transferable,
          nonexclusive, revocable license to use the Venn Platform pursuant to
          these Terms of Use and to any additional terms and policies set forth
          by Venn; and (b) you agree not to reproduce, distribute, create
          derivative works from, publicly display, publicly perform, license,
          sell, or re-sell any content, software, products, or services obtained
          from or through the Venn Platform without the express permission of
          Venn.
        </p>
        <h2 className="text-xl font-bold pt-10">3. Use of the Venn Platform</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          As a condition of your use of the Venn Platform, you agree that:
        </p>
        <ul className="list-disc">
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            You have reached the age of majority in the state in which you
            reside;
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            You are able to create a binding legal obligation;
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            You are not barred from receiving products or services under
            applicable law;
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            You will not attempt to use the Venn Platform with crawlers, robots,
            data mining, or extraction tools or any other functionality;
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Your use of the Venn Platform will at all times comply with these
            Terms of Use;
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            You will only make legitimate purchases that comply with the letter
            and spirit of the terms of the respective offers;
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            You will only make purchases on the Venn Platform for your own use
            and enjoyment or as a gift for another person;
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            You have the right to provide any and all information you submit to
            the Venn Platform, and all such information is accurate, true,
            current, and complete;
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            You will update and correct information you have submitted to the
            Venn Platform, including all account information, and ensure that it
            is accurate at all times (out-of-date information will invalidate
            your account); and,
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            You will only purchase a Merchant Offering, Organiser Offering,
            Product, or participate in other available programs through the Venn
            Platform by creating an account or using the guest checkout feature
            on the Venn Platform, and any purchase will be subject to the
            applicable Terms of Sale set forth in these Terms of Use.
          </li>
        </ul>
        <h2 className="text-xl font-bold pt-10">
          4. Access to the Venn Platform
        </h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Venn retains the right, at our sole discretion, to deny service or use
          of the Venn Platform or an account to anyone at any time and for any
          reason. While we use reasonable efforts to keep the Venn Platform and
          your account accessible, the Venn Platform and/or your account may be
          unavailable from time to time. You understand and agree that there may
          be interruptions in service or events, Venn Platform access, or access
          to your account due to circumstances both within our control (e.g.,
          routine maintenance) and outside of our control.
        </p>
        <h2 className="text-xl font-bold pt-10">5. Service</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Subscription: The user must create an account and pay the subscription
          fee (“Subscription Fee”) in order to avail the services offered by
          Venn (“Subscription Service”). The acceptance of the subscription will
          only be confirmed when the user receives the Welcome Email and/or
          Payment Receipt (“Welcome Email/Payment Receipt””). The Agreement
          between Venn will be deemed to valid on the same event i.e., receiving
          of the Welcome Email and/or Payment Receipt. (“Agreement”).
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The Subscription Services consist of an initial charge and then
          followed by recurring period charges as agreed by the user. By
          entering into this Agreement, you acknowledge that your subscription
          has an initial and recurring payment feature and you accept
          responsibility for all recurring charges prior to cancellation.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The Subscription Service and any feature, software or content that is
          made available through the Subscription Service (“Content”) are the
          property of Venn, Venn's licensors or third parties. We grant you
          limited, non-exclusive, non-transferable and revocable permission to
          make use of the Subscription Service, and limited, non-exclusive,
          revocable permission to make personal use of the features, software
          and Content (collectively, “Access”). This Access shall remain in
          effect until and unless your Paid subscription terms ends
          (“Subscription Period”) and/or Access is terminated by you or Venn.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          There are different modes of Subscription Period offered by Venn:
        </p>
        <ul className="list-disc">
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            an ongoing/continuous renewing period during which the user is
            agreeing to pay recurring periodic subscription fees for an
            indefinite amount until cancelled by the user. (Ongoing
            Subscription); or
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            limited to a specific period of time, which may or may not be
            capable of renewal (Periodic Subscription);
          </li>
        </ul>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The services offered by Venn on the platform may depend upon the
          subscription plan (“Subscription Plan”) purchased by the user and
          consequently may affect the functionality and/or access to the
          platform. Venn may also impose limits on certain features or restrict
          your access to parts or all of the Subscription Services without
          notice or liability
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The user is required to provide accurate information and true
          representation. Subscription Services, fee and registration data and
          certain other information about the User are governed by the Privacy
          Policy. The user is solely responsible for keeping the account
          password and any OTP received secure so that no third parties apart
          from the user should access, use and utilize the Subscription
          Services. You must notify Venn immediately of any breach of security
          or unauthorized use of your account.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The user also agrees to (a) not to violate any laws in connection with
          the use of the Subscription Services; (b) not to interfere with or try
          to disrupt our Subscription Services, for example by distributing a
          virus or other harmful computer code into our platforms, third party
          services, or other programs or systems our clients may use to promote
          their products; and (c) copy, sell, lease or otherwise provide access
          to Subscription Services to any third party.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          In order to avail the offers on total dining bill at any of the
          mentioned Partner Restaurants of Venn, the User must be physically
          present at the particular establishment. The User’s contact details
          cannot be made use of by any other individual or party present at the
          establishment.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          If the Venn Platform is provided on an Ongoing Subscription or
          Periodic Subscription basis, the Subscription will continue for such
          time as all applicable subscription fees are paid to Venn (or until
          the set period for which fees have been paid expires), unless
          terminated earlier under these Terms.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Venn may change the Subscription Fees payable with respect to the
          Subscription Services offered by giving the user a 10 days’ notice.
          The change in fees would apply from the next date of payment. In the
          event of the user denying to accept the changes in the subscription
          fee, the agreement shall stand void and the user will not be able to
          access the platform. If the user does not accept the new subscription
          fee, then the subscription services will expire at the end of the
          current Subscription Period.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Venn offers certain benefits to users who have purchased/signed up for
          the membership program (“Membership Program”).
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The Subscription Service may change periodically. The offers on the
          Subscription Service may also change periodically. Venn reserves the
          right to modify, suspend or discontinue, temporarily or permanently,
          any certain Subscription Service with prior notice to the user. with
          prior notice to you. The user may be notified through the Services
          user interface and/or email notification with relation to any change
          in the Subscription Services. Any change as notified by Venn will
          become effective no earlier than fourteen (14) days after they are
          informed to the user. However, changes addressing new functions of the
          Services or Membership and/or changes made for legal reasons will be
          effective immediately.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The Subscription fee is non-refundable. We reserve the right to accept
          or refuse Membership at our discretion. Certain offers which are part
          of our Subscription Service are limited in nature and are offered on a
          first-come, first-serve basis. Venn does nor guarantee that all
          subscribers will avail the offers mentioned on the platform.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The user’s Paid Subscription will automatically renew each month or
          year depending on the Subscription Period the user has chosen without
          notice until cancellation. The user authorizes us to store the user’s
          payment method(s) and to automatically charge payment method(s) every
          month or year until cancellation. We will automatically charge the
          then-current rate for your Paid Subscription and applicable taxes
          every month or year upon renewal until you cancel.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The user represents that the information given is true and the user is
          authorized to use the payment instrument. The user must update the
          account information in case of any change in the details provided by
          the user. The user must provide an alternative payment method in case
          the existing payment method has failed. The user will be notified on
          the Venn Platform incase of failure of payment, card expiration,
          failed renewal and any other notification related to the Payment
          Gateway. If you provide us with a new eligible payment method and are
          successfully charged, your new membership period will be based on the
          original renewal date and not the date of the successful charge.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Venn may change the Subscription Fee’s rate, and we will notify you of
          any applicable rate change with the option to cancel. Subject to
          applicable law, you accept the new price by continuing to use the
          Subscription Services after the price change takes effect.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Termination of Subscription: The user may cancel their Subscription
          Service at any time by writing to us at{" "}
          <a href="mailto:support@puttingscene.com">support@puttingscene.com</a>
          . If you cancel your Membership, Venn will not refund any membership
          fees you have already paid.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The user may cancel the Subscription Service at any time; however,
          there are no refunds for cancellation, and the user understands and
          agrees that the user shall receive no refund or exchange for any
          unused time of Subscription Services according to the chosen
          Subscription Service.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Venn may terminate or suspend your Subscription Service at any time
          and for any reason by notice to the user. Suspension of your
          Subscription Service may result in restricted access of Venn’s
          platform. In any situation the discretion of Venn is final.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          UNLESS YOU NOTIFY US BEFORE A CHARGE THAT YOU WANT TO CANCEL OR DO NOT
          WANT TO AUTO RENEW, YOU UNDERSTAND YOUR MEMBERSHIP WILL AUTOMATICALLY
          CONTINUE AND YOU AUTHORIZE US (WITHOUT NOTICE TO YOU, UNLESS REQUIRED
          BY APPLICABLE LAW) TO COLLECT THE THEN-APPLICABLE MEMBERSHIP FEE AND
          ANY TAXES, USING ANY ELIGIBLE PAYMENT METHOD WE HAVE ON RECORD FOR
          YOU.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          It is imperative to mention that Venn will follow and abide by all
          current and future rules of the RBI with reference to but not limited
          to payment gateways, rules and regulations.
        </p>
        <h2 className="text-xl font-bold pt-10">6. Modification</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          We reserve the right at all times to discontinue or modify any part of
          these Terms of Use in our sole discretion. If we make changes that
          affect your use of the Venn Platform or our services, we will post
          notice of the change on the Terms of Use page. Any changes to these
          Terms of Use will be effective upon our posting of the notice;
          provided that these changes will be prospective only and not
          retroactive. If you do not agree to the changes, you may close your
          account and you should not use the Venn Platform or any services
          offered through the Venn Platform after the effective date of the
          changes. We suggest that you revisit our Terms of Use regularly to
          ensure that you stay informed of any changes. You agree that posting
          notice of any changes on the Terms of Use page is adequate notice to
          advise you of these changes, and that your continued use of the Venn
          Platform or our services will constitute acceptance of these changes
          and the Terms of Use as modified.
        </p>
        <h2 className="text-xl font-bold pt-10">7. Your Account</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          You can only create and hold one account with one Email ID and Mobile
          number. You are responsible for updating and correcting information
          you have submitted to create or maintain your account. As part of your
          account settings, you have the option to: (a) save and/or edit your
          personal information, including, without limitation; and (b) opt-out
          of persistent login. You understand and agree that Venn shall have no
          responsibility for any incident arising out of, or related to, your
          account settings. You must safeguard your password and supervise the
          use of your account. You are solely responsible for maintaining the
          security of your account and maintaining settings that reflect your
          preferences. We will assume that anyone using the Venn Platform or
          transacting through your account is you. You agree that you are solely
          responsible for any activity that occurs under your account.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Opt In Policy: The user explicitly consents to receive messages from
          Venn on WhatsApp by registering with Venn. It is imperative to note
          that a Non-registered User commencing communication on the Whatsapp
          Venn account cannot be considered a part of the Opt In Policy. The
          user will be required to provide validation of consent post
          registration for them to be considered a part of this Policy.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Opt Out Policy: No refunds or cancellations can be issued once the
          membership has been purchased, the User may opt out for the service by
          writing Venn an email at{" "}
          <a href="mailto:support@puttingscene.com">support@puttingscene.com</a>{" "}
          . If the User chooses to opt out, the user may no longer receive
          electronic communications from Venn and will not be able to avail any
          of the benefits included in the membership plan. However, Venn will be
          available to respond to your queries and/or issues on e-mail.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Your account is non-transferrable. You cannot sell, combine, or
          otherwise share it with any other person. Any violation of these Terms
          of Use, including, without limitation, failure to maintain updated and
          correct information about your account (e.g., valid credit card
          information) will cause your account to fall out of good standing and
          we may cancel your account at our sole discretion. Upon termination,
          the provisions of these Terms of Use that are, by their nature,
          intended to survive termination (e.g., any disclaimers, all
          limitations of liability, and all indemnities) shall survive. We also
          reserve the right to change or discontinue any aspect or feature of
          our services or the Venn Platform, including, without limitation,
          requirements for use.
        </p>
        <h2 className="text-xl font-bold pt-10">8. Your Conduct</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          All interactions on the Venn Platform must comply with these Terms of
          Use. To the extent your conduct, in our sole discretion, restricts or
          inhibits any other user from using or enjoying any part of the Venn
          Platform, we may limit or terminate your privileges on the Venn
          Platform and seek other remedies, including, without limitation,
          cancellation of your account or forfeiture of any forms of unredeemed
          value in your account.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The following activities are prohibited on the Venn Platform and
          constitute violations of these Terms of Use:
        </p>
        <ul className="list-disc">
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
              Submitting any content to the Venn Platform that:
            </p>
            <ul className="list-disc">
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Violates applicable laws (including, without limitation,
                intellectual property laws, laws relating to rights of privacy
                and rights of publicity, and laws related to defamation);
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Contains personal information, except when we expressly ask you
                to provide such information;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Contains viruses or malware;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Offers unauthorized downloads of any copyrighted, confidential,
                or private information;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Has the effect of impersonating others;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Contains messages by non-spokesperson employees of Venn
                purporting to speak on behalf of Venn or provides confidential
                information concerning Venn;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Contains chain letters of any kind;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Is purposely inaccurate, commits fraud, or falsifies information
                in connection with your Venn account or to create multiple Venn
                accounts; or
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Is protected by copyright, trademark, or other proprietary right
                without the express permission of the owner of the copyright,
                trademark or other proprietary right.
              </li>
            </ul>
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
              Attempting to do or actually doing any of the following:
            </p>
            <ul className="list-disc">
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Accessing data not intended for you, such as logging into a
                server or an account which you are not authorized to access;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Scanning or monitoring the Venn Platform for data gathering
                purposes in an effort to track sales, usage, aggregate offering
                information, pricing information, or similar data;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Scanning or testing the security or configuration of the website
                or breaching security or authentication measures; or
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Interfering with service to any user in any manner, including,
                without limitation, by means of submitting a virus to the Venn
                Platform or attempting to overload, “flood,” “spam,” “mail
                bomb,” or “crash” the website.
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Creating any frame with relation to our website that may/will
                alter the visual presentation/appearance of our website
              </li>
            </ul>
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
              Using any of the following:
            </p>
            <ul className="list-disc">
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Frames, framing techniques, or framing technology to enclose any
                content included on the Venn Platform without our express
                written permission;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Any Venn Platform content, including, without limitation, User
                Content (defined below), in any meta tags or any other “hidden
                text” techniques or technologies without our express written
                permission;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                The Venn Platform or any of its contents to advertise or
                solicit, for any commercial, political, or religious purpose or
                to compete, directly or indirectly, with Venn; or
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                The Venn Platform or any of its resources to solicit consumers,
                Merchants, or other third-parties to become users or partners of
                other online or offline services directly or indirectly
                competitive or potentially competitive with Venn, including,
                without limitation, aggregating current or previously offered
                deals.
              </li>
            </ul>
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
              Collecting any of the following:
            </p>
            <ul className="list-disc">
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Content from the Venn Platform, including, without limitation,
                in connection with current or previously offered deals, and
                featuring such content to consumers in any manner that diverts
                traffic from the Venn Platform without our express written
                permission; or
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Personal Information (defined in our Privacy Statement), User
                Content (defined in Section 12 below), or content of any
                consumers or Merchants.
              </li>
            </ul>
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
              Engaging in any of the following:
            </p>
            <ul className="list-disc">
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Tampering or interfering with the proper functioning of any
                part, page, or area of the Venn Platform or any functions or
                services provided by Venn;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Taking any action that places excessive demand on our services
                or imposes, or may impose, an unreasonable or disproportionately
                large load on our servers or other portion of our infrastructure
                (as determined in our sole discretion);
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Reselling or repurposing your access to the Venn Platform or any
                purchases made through the Venn Platform;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Exceeding or attempting to exceed quantity limits when
                purchasing Merchant Offerings or Products, or otherwise using
                any Venn account to purchase Merchant Offerings or Products for
                resale or for speculative, false, fraudulent, or any other
                purpose not expressly permitted by these Terms of Use and the
                terms of a specific offer on the Venn Platform;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Accessing, monitoring, or copying any content from the Venn
                Platform using any “robot,” “spider,” “scraper,” or other
                automated means or any manual process for any purpose without
                our express written permission;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Violating the restrictions in any robot exclusion headers on the
                Venn Platform or bypassing or circumventing other measures
                employed to prevent or limit access to the Venn Platform;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Aggregating any current or previously-offered deals or content
                or other information from the Venn Platform (whether using links
                or other technical means or physical records associated with
                purchases made through the Venn Platform) with material from
                other Venn Platforms or on a secondary website without our
                express written permission;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Deep-linking to any portion of the Venn Platform (including,
                without limitation, the purchase path for any Voucher) without
                our express written permission;
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Hyperlinking to the Venn Platform from any other website without
                our initial and ongoing consent; or
              </li>
              <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
                Acting illegally or maliciously against the business interests
                or reputation of Venn, our Merchants, or our services.
              </li>
            </ul>
          </li>
        </ul>
        <h2 className="text-xl font-bold pt-10">9. Your Privacy</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          We take the privacy of your Personal Information (defined in the{" "}
          <a href="https://events.venn.buzz/privacy">Privacy Policy</a> )
          seriously. We encourage you to carefully review our Privacy Policy for
          important disclosures about ways that we may collect, use, and share
          personal data and your choices. Our Privacy Statement is incorporated
          in and subject to these Terms of Use.
        </p>
        <h2 className="text-xl font-bold pt-10">
          10. Copyright and Trademarks
        </h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The Venn Platform contains copyrighted material, trademarks, and other
          proprietary information, including, without limitation, text,
          software, photos, video, graphics, music, and sound, and the entire
          contents of the Venn Platform are protected by copyright, trademark,
          and other intellectual property laws of the India. Venn owns a
          copyright in the selection, coordination, arrangement, and enhancement
          of such content, as well as in the content original to it. You may not
          modify, distribute, publish, transmit, publicly display, publicly
          perform, participate in the transfer or sale, create derivative works,
          or in any way exploit any of the content, in whole or in part. Except
          as otherwise expressly stated under copyright law, no downloading,
          copying, redistribution, retransmission, publication, or commercial
          exploitation of the content without the express permission of Venn or
          the copyright owner is permitted. If downloading, copying,
          redistribution, retransmission, or publication of copyrighted material
          is permitted, you will make independent attribution and/or make no
          changes in or deletion of any author attribution, trademark legend, or
          copyright notice. You acknowledge that you do not acquire any
          ownership rights by downloading copyrighted material. Any violation of
          these restrictions may result in a copyright, trademark, or other
          intellectual property right infringement that may subject you to civil
          and/or criminal penalties.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Venn owns trademarks, registered and unregistered, in India and
          "Venn," the Venn logos and variations thereof found on the Venn
          Platform are trademarks owned by Putting Scene Ventures Private
          Limited or its related entities and all use of these marks inures to
          the benefit of Venn.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Other marks on the Venn Platform not owned by Venn may be under
          license from the trademark owner thereof, in which case such license
          is for the exclusive benefit and use of Venn unless otherwise stated,
          or may be the property of their respective owners. You may not use
          Venn's name, logos, trademarks or brands, or trademarks or brands of
          others on the Venn Platform without Venn's express permission.
        </p>
        <h2 className="text-xl font-bold pt-10">
          11. Hyperlinking to our Content
        </h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The following organizations may link to our website without prior
          written approval:
        </p>
        <ul className="list-disc">
          Government agencies; Search engines; News organisations; Online
          directory distributors may link to our website in the same manner as
          they hyperlink to the websites of other listed businesses; and System
          wide Accredited Businesses except soliciting non-profit organisations,
          charity shopping malls, and charity fundraising groups which may not
          hyperlink to our website.
        </ul>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          These organisations may link to our home page, to publications or to
          other Venn Platform information so long as the link: (a) is not in any
          way deceptive; (b) does not falsely imply sponsorship, endorsement or
          approval of the linking party and its products and/or services; and
          (c) fits within the context of the linking party’s site.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          We may consider and approve other link requests from the following
          types of organisations:
        </p>
        <ul className="list-disc">
          commonly-known consumer and/or business information sources; dot.com
          community sites; associations or other groups representing charities;
          online directory distributors; internet portals; accounting, law and
          consulting firms; and educational institutions and trade associations.
        </ul>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          We will approve link requests from these organizations if we decide
          that: (a) the link would not make us look unfavourably to ourselves or
          to our accredited businesses; (b) the organization does not have any
          negative records with us; (c) the benefit to us from the visibility of
          the hyperlink compensates the absence of Venn by Putting Scene
          Ventures Private Limited; and (d) the link is in the context of
          general resource information.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          These organizations may link to our home page so long as the link: (a)
          is not in any way deceptive; (b) does not falsely imply sponsorship,
          endorsement or approval of the linking party and its products or
          services; and (c) fits within the context of the linking party’s site.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Approved organizations may hyperlink to our website as follows:
        </p>
        <ul className="list-disc">
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            By use of our corporate name; or
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            By use of the uniform resource locator being linked to; or
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            By use of any other description of our website being linked to that
            makes sense within the context and format of content on the linking
            party’s site.
          </li>
        </ul>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          No use of Venn by Putting Scene Ventures Private Limited's logo or
          other artwork will be allowed for linking absent a trademark license
          agreement.
        </p>
        <h2 className="text-xl font-bold pt-10">12. Unsolicited Ideas</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          We do not accept or consider, directly or through any Venn employee or
          agent, unsolicited ideas of any kind, including, without limitation,
          ideas or suggestions relating to new or improved products,
          enhancements, names or technologies, advertising and marketing
          campaigns, plans, or other promotions. Do not send us (or any of our
          employees) any unsolicited ideas, suggestions, material, images, or
          other work in any form (“Unsolicited Materials”). If you send us
          Unsolicited Materials, you understand and agree that the following
          terms will apply, notwithstanding any cover letter or other terms that
          accompany them:
        </p>
        <ul className="list-disc">
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Venn has no obligation to review any Unsolicited Materials, nor to
            keep any Unsolicited Materials confidential; and
          </li>
          <li className="text-md d pt-4 px-7 max-sm:text-sm max-sm:pt-4">
            Venn will own, and may use and redistribute, Unsolicited Materials
            for any purpose without restriction and free of any obligation to
            acknowledge or compensate you.
          </li>
        </ul>
        <h2 className="text-xl font-bold pt-10">13. Disclaimer of Warranty</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          AS PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY AGREE THAT USE OF THE
          VENN PLATFORM IS AT YOUR SOLE RISK. NEITHER VENN, NOR ITS SUBSIDIARIES
          OR AFFILIATES OR ANY OF THEIR RESPECTIVE EMPLOYEES, AGENTS, MERCHANTS,
          THIRD-PARTY CONTENT PROVIDERS OR LICENSORS OR ANY OF THEIR OFFICERS,
          DIRECTORS, EMPLOYEES OR AGENTS, WARRANT THAT USE OF THE VENN PLATFORM
          WILL BE UNINTERRUPTED, SECURE, VIRUS-FREE, OR ERROR FREE, NOR DO THEY
          MAKE ANY WARRANTY AS TO (A) THE RESULTS THAT MAY BE OBTAINED FROM USE
          OF THE VENN PLATFORM, OR (B) THE ACCURACY, COMPLETENESS, OR
          RELIABILITY OF (I) THE CONTENT ON THE VENN PLATFORM, INCLUDING,
          WITHOUT LIMITATION, MERCHANT OFFERINGS, PRODUCTS, OR OTHER AVAILABLE
          PROGRAMS, (II) DESCRIPTIONS OF MERCHANT OFFERINGS, PRODUCTS, OR OTHER
          AVAILABLE PROGRAMS, OR (III) USER CONTENT PROVIDED THROUGH THE VENN
          PLATFORM. THE VENN PLATFORM AND ALL CONTENT, USER CONTENT AND OTHER
          INFORMATION CONTAINED ON THE VENN PLATFORM, MERCHANT OFFERINGS,
          PRODUCTS AND OTHER AVAILABLE PROGRAMS ACCESSIBLE OR AVAILABLE THROUGH
          THE VENN PLATFORM, ARE MADE ACCESSIBLE OR AVAILABLE ON AN “AS IS” AND
          “AS AVAILABLE” BASIS. TO THE EXTENT ALLOWED BY APPLICABLE LAW, Venn
          HEREBY DISCLAIMS ANY AND ALL REPRESENTATIONS, WARRANTIES, AND
          CONDITIONS, WHETHER EXPRESS, IMPLIED, OR STATUTORY, AS TO THE
          OPERATION OF THE VENN PLATFORM OR THE CONTENT, USER CONTENT, OR OTHER
          INFORMATION CONTAINED ON THE VENN PLATFORM OR THE MERCHANT OFFERINGS,
          PRODUCTS, OR OTHER AVAILABLE PROGRAMS ACCESSIBLE OR AVAILABLE THROUGH
          THE VENN PLATFORM, INCLUDING, WITHOUT LIMITATION, THOSE OF TITLE,
          NON-INFRINGEMENT, NON-INTERFERENCE, MERCHANTABILITY, SUITABILITY, AND
          FITNESS FOR A PARTICULAR PURPOSE, AS WELL AS WARRANTIES IMPLIED FROM A
          COURSE OF PERFORMANCE OR COURSE OF DEALING. THE WARRANTY LIMITATIONS
          IN THIS SECTION ARE NOT INTENDED TO LIMIT ANY WARRANTY PROVIDED
          DIRECTLY BY A MERCHANT OR BY THE APPLICABLE MANUFACTURER OF PHYSICAL
          PRODUCTS OR ANY EXPRESS REPRESENTATIONS OR WARRANTIES BY Venn THAT ARE
          INCLUDED IN OTHER APPLICABLE TERMS.
        </p>
        <h2 className="text-xl font-bold pt-10">14. Limitation of Liability</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          AS PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL Venn, ITS
          SUBSIDIARIES OR AFFILIATES OR ANY OF THEIR RESPECTIVE EMPLOYEES,
          OFFICERS, DIRECTORS, AGENTS, MERCHANTS, PARTNERS, THIRD-PARTY CONTENT
          PROVIDERS OR LICENSORS, OR ANY OF THEIR OFFICERS, DIRECTORS,
          EMPLOYEES, OR AGENTS, BE LIABLE FOR ANY DIRECT OR INDIRECT LOST
          PROFITS OR LOST BUSINESS DAMAGES, INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF, RELATED TO, OR IN
          CONNECTION WITH ANY OF THE FOLLOWING: (A) YOUR USE OF THE VENN
          PLATFORM, THE CONTENT, USER CONTENT, INCLUDING, WITHOUT LIMITATION,
          ANY PERSONAL INFORMATION, AND ANY OTHER INFORMATION EITHER CONTAINED
          IN THE VENN PLATFORM OR SUBMITTED BY YOU TO THE VENN PLATFORM; (B)
          YOUR INABILITY TO USE THE VENN PLATFORM; (C) MODIFICATION OR REMOVAL
          OF CONTENT SUBMITTED ON THE VENN PLATFORM; (D) THE MERCHANT OFFERINGS,
          PRODUCTS, AND OTHER AVAILABLE PROGRAMS ACCESSIBLE OR AVAILABLE THROUGH
          THE VENN PLATFORM; (E) ANY PRODUCTS OR SERVICES PURCHASED OR OBTAINED
          DIRECTLY FROM A MERCHANT; (F) THESE TERMS OF USE; OR (G) ANY IMPROPER
          USE OF INFORMATION YOU PROVIDE TO THE VENN PLATFORM, INCLUDING,
          WITHOUT LIMITATION, ANY PERSONAL INFORMATION. IN NO EVENT WILL Venn’S
          LIABILITY IN CONNECTION WITH A MERCHANT OFFERING, PRODUCT, AND OTHER
          AVAILABLE PROGRAMS EXCEED THE AMOUNTS PAID FOR THE APPLICABLE VOUCHER,
          PRODUCT, OR SERVICE. THE LIABILITY LIMITATIONS IN THIS SECTION ARE NOT
          INTENDED TO LIMIT ANY WARRANTY PROVIDED DIRECTLY BY A MERCHANT OR BY
          THE APPLICABLE MANUFACTURER OF PHYSICAL PRODUCTS OR ANY EXPRESS
          REPRESENTATIONS OR WARRANTIES BY Venn THAT ARE INCLUDED IN OTHER
          APPLICABLE TERMS, NOR ARE THEY INTENDED TO LIMIT REMEDIES YOU MIGHT
          HAVE FOR PRODUCT-RELATED INJURY.
        </p>
        <h2 className="text-xl font-bold pt-10">
          15. Electronic Communications
        </h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          When you use the Venn Platform or send emails to Venn, you are
          communicating with us electronically and consent to receive electronic
          communications related to your use of the Venn Platform. We will
          communicate with you by Whatsapp Business, email and/ or by posting
          notices on the Venn Platform. You agree that all agreements, notices,
          disclosures, and other communications that are provided to you
          electronically satisfy any legal requirement that such communications
          be in writing. Notices from us will be considered delivered to you and
          effective when sent to the email address you provide on the Venn
          Platform or from which you otherwise email us.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The User hereby accepts and agrees that all Services and
          communications (both One Way Communication and Two-Way Communication)
          taking place on WhatsApp, initiated either by Venn or the User, will
          be governed by and subject to these Terms and Conditions. Further, the
          User hereby agrees that the User grants express authority to Venn for
          carrying out the Services requested by the User on WhatsApp on its
          Venn Registered Mobile Number. Venn shall have no obligation to
          authenticate the User or to verify the identity of any User once the
          registration for the service is made or once it receives any request/
          communication to its Venn Registered Number from any WhatsApp User.
          Venn's own record or log of transactions maintained through computer
          systems but not limited to the same shall be accepted as conclusive
          and binding for all purposes.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          By subscribing to Venn’s service, the User agrees to get its personal
          notifications/alerts/acknowledgements via WhatsApp including but not
          limited to, transaction details, added subscriptions, promotional
          alerts, notification services etc. The User also agrees to receive
          notifications including Account and transaction alert notifications,
          updates, personalised offers, new events etc. on his mobile number
          registered with Venn via WhatsApp. Further, by registering to the
          service on WhatsApp, the User authorises Venn to send the
          aforementioned alerts to the User on WhatsApp and/or SMS at Venn’s
          sole discretion and Venn also may stop sending these alerts on SMS or
          any other channel, at its sole discretion.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The User understands that using WhatsApp may carry extra risks.
          Further, any message and information exchanged on WhatsApp shall be
          subject to the risk of being read, interrupted, intercepted, or
          defrauded by a third party or otherwise subject to manipulation by
          third party or involve delay in transmission. Venn shall not be
          responsible or liable to you or any third party for the consequences
          arising out of or in connection with using this Service.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The User is aware that using any mobile application involves many
          uncertain factors and complex software, hardware, systems, etc. which
          are susceptible to interruptions and dislocations. Venn does not make
          any representation or warranty that the Services will be available at
          all times without any interruption and that Venn shall not be
          responsible for any variation, reduction or imposition of the terms or
          the User’s inability to use WhatsApp for any reason whatsoever.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The User further agrees that the WhatsApp application is owned and
          controlled by an independent third party which is unaffiliated with
          Venn. Venn is not responsible for the privacy or security policies of
          WhatsApp and any other entity that may be linked to/ from/ within it,
          and does not assume liability for any damage incurred by the User from
          the use of said application. The User fully understands that Venn is
          not endorsing the products or services of WhatsApp or of any
          advertisement etc. appearing thereon.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The User hereby expressly acknowledges and confirms that the User has
          read, verified, understood, irrevocably agreed to and accepted and
          delivered all the terms and conditions contained herein online by
          sending a message/ sending any communication on WhatsApp on the Venn
          Registered Number. The User hereby expressly acknowledges and confirms
          that at the time of accepting and signing these Terms and Conditions
          as above, the User fulfils the eligibility to utilise the Services as
          provided in these Terms and Conditions and that the User is (i) an
          individual; (ii) a major; (iii) of sound mind, solvent and competent
          to contract; (iv) a resident of India; and (v) is present within the
          territory of India at the time of utilisation of the Services.
        </p>
        <h2 className="text-xl font-bold pt-10">16. Websites of Others</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The Venn Platform may contain links to Websites maintained by other
          parties. These links are provided solely as a convenience to you and
          not because we endorse or have an opinion about the contents on such
          Websites. We expressly disclaim any representations regarding the
          content or accuracy of materials on such Websites or the privacy
          practices of those Websites. If you decide to access Websites
          maintained by other parties, you do so at your own risk. We are not
          responsible or liable, directly or indirectly, for any damage, loss,
          or liability caused or alleged to be caused by or in connection with
          any use of or reliance on any content, Products, or services available
          on or through any such linked website or resource.
        </p>
        <h2 className="text-xl font-bold pt-10">17. Indemnification/Release</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          You agree to defend, indemnify, and hold harmless Venn, its
          subsidiaries and affiliates, and their respective directors, officers,
          employees and agents from and against all claims and expenses,
          including, without limitation, attorneys’ fees, arising out of,
          related to, or in connection with any of the following: (a) any User
          Content submitted or posted by you, in connection with the Venn
          Platform, or any use of the Venn Platform in violation of these Terms
          of Use; (b) fraud you commit or your intentional misconduct or gross
          negligence; or (c) your violation of any applicable Indian law or
          foreign law or rights of a third-party.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          You are solely responsible for your interactions with Merchants and
          other users of the Venn Platform. To the extent permitted under
          applicable laws, you hereby release Venn from any and all claims or
          liability related to any product or service of a Merchant, regardless
          of whether such product or service is a Merchant Offering available
          through the Venn Platform, any action or inaction by a Merchant,
          including, without limitation, but not limited to any harm caused to
          you by action or inaction of a Merchant, a Merchant’s failure to
          comply with applicable law and/or failure to abide by the terms of a
          Merchant Offering or any other product or service purchased or
          obtained by you from the Merchant, and any conduct, speech or User
          Content, whether online or offline, of any other third-party.
        </p>
        <h2 className="text-xl font-bold pt-10">18. Force Majeure</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Venn shall be excused from performance under these Terms of Use, to
          the extent it or a Merchant is prevented or delayed from performing,
          in whole or in part, as a result of an event or series of events
          caused by or resulting from: (a) weather conditions or other elements
          of nature or acts of God; (b) acts of war, acts of terrorism,
          insurrection, riots, civil disorders, or rebellion; (c) quarantines or
          embargoes; (d) labor strikes; (e) error or disruption to major
          computer hardware or networks or software failures; or (g) other
          causes beyond the reasonable control of Venn or a Merchant/Restaurant
          Partner, as applicable.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          You may not assign these Terms of Use, or any rights, benefits, or
          obligations hereunder, by operation of law or otherwise, without the
          express written permission of Venn. Any attempted assignment that does
          not comply with these Terms of Use shall be null and void. Venn may
          assign these Terms of Use, in whole or in part, to any third-party in
          its sole discretion.
        </p>
        <h2 className="text-xl font-bold pt-10">19. Entire Agreement</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The Terms of Use, including, without limitation, the incorporated
          Terms of Sale, Special Programs, Privacy Policy, and other terms
          incorporated by reference, constitute the entire agreement and
          understanding between you and Venn with respect to the subject matter
          hereof and supersedes all prior or contemporaneous communications and
          proposals, whether oral or written, between you and Venn with respect
          to such subject matter.
        </p>
        <h2 className="text-xl font-bold pt-10">20. Cookies</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          This website uses cookies to improve the user experience and ensure
          that it is functioning effectively, and on occasion also to provide
          marketing information or advertise online, either directly or through
          third party vendors.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          For more information about Venn, and how it protects your information,
          please see the Privacy Policy. To provide you with a more personalised
          and responsive service, Venn needs to remember and store information
          about how you use this website. This is done using small text files
          called cookies. Cookies contain small amounts of information and are
          downloaded to your computer or other device by a server for this
          website. Your web browser then sends these cookies back to this
          website on each subsequent visit so that it can recognize you and
          remember things like your user preferences. For e.g., we may allow
          Google to set a cookie on your browser while you visit the Venn
          Platform, and that would be a third-party cookie. Cookies may also
          endure for different periods of time. “Session Cookies' ' only last
          only as long as your browser is open. These are deleted automatically
          once you close your browser. Other cookies are “persistent cookies''
          meaning that they survive after your browser is closed. For example,
          they may recognize your device when you re-open your browser and
          browse the internet again.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Whenever you use this website, information may be collected using
          cookies and other technologies. By using this website, you agree to
          Venn's use of cookies as described in this Cookie Policy.
        </p>
        <h2 className="text-xl font-bold pt-10">
          21. Governing Law and Dispute Resolution
        </h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          These Terms of Use are governed by the laws of India. Any action,
          suit, arbitration or other legal proceeding, which is commenced to
          resolve any matter arising under or relating to this Venn Platform,
          shall be subject to the jurisdiction of the courts at Bangalore,
          India.
        </p>
        <h2 className="text-xl font-bold pt-10">22. Disclaimers</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          THE PLATFORM MAY BE UNDER CONSTANT UPGRADES, AND SOME FUNCTIONS AND
          FEATURES MAY NOT BE FULLY OPERATIONAL.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          DUE TO THE VAGARIES THAT CAN OCCUR IN THE ELECTRONIC DISTRIBUTION OF
          INFORMATION AND DUE TO THE LIMITATIONS INHERENT IN PROVIDING
          INFORMATION OBTAINED FROM MULTIPLE SOURCES, THERE MAY BE DELAYS,
          OMISSIONS, OR INACCURACIES IN THE CONTENT PROVIDED ON THE PLATFORM OR
          DELAY OR ERRORS IN FUNCTIONALITY OF THE PLATFORM. AS A RESULT, WE DO
          NOT REPRESENT THAT THE INFORMATION POSTED IS CORRECT IN EVERY CASE.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          WE EXPRESSLY DISCLAIM ALL LIABILITIES THAT MAY ARISE AS A CONSEQUENCE
          OF ANY UNAUTHORIZED USE OF CREDIT/ DEBIT CARDS.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          YOU ACKNOWLEDGE THAT THIRD PARTY SERVICES ARE AVAILABLE ON THE
          PLATFORM. WE MAY HAVE FORMED PARTNERSHIPS OR ALLIANCES WITH SOME OF
          THESE THIRD PARTIES FROM TIME TO TIME IN ORDER TO FACILITATE THE
          PROVISION OF CERTAIN SERVICES TO YOU. HOWEVER, YOU ACKNOWLEDGE AND
          AGREE THAT AT NO TIME ARE WE MAKING ANY REPRESENTATION OR WARRANTY
          REGARDING ANY THIRD PARTY'S SERVICES NOR WILL WE BE LIABLE TO YOU OR
          ANY THIRD PARTY FOR ANY CONSEQUENCES OR CLAIMS ARISING FROM OR IN
          CONNECTION WITH SUCH THIRD PARTY INCLUDING, AND NOT LIMITED TO, ANY
          LIABILITY OR RESPONSIBILITY FOR, DEATH, INJURY OR IMPAIRMENT
          EXPERIENCED BY YOU OR ANY THIRD PARTY. YOU HEREBY DISCLAIM AND WAIVE
          ANY RIGHTS AND CLAIMS YOU MAY HAVE AGAINST US WITH RESPECT TO THIRD
          PARTY'S / MERCHANT’S/ PDP’S SERVICES.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Venn DISCLAIMS AND ALL LIABILITY THAT MAY ARISE DUE TO ANY VIOLATION
          OF ANY APPLICABLE LAWS INCLUDING THE LAW APPLICABLE TO PRODUCTS AND
          SERVICES OFFERED BY THE MERCHANT OR PDP.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          WHILE THE MATERIALS PROVIDED ON THE PLATFORM WERE PREPARED TO PROVIDE
          ACCURATE INFORMATION REGARDING THE SUBJECT DISCUSSED, THE INFORMATION
          CONTAINED IN THESE MATERIALS IS BEING MADE AVAILABLE WITH THE
          UNDERSTANDING THAT WE MAKE NO GUARANTEES, REPRESENTATIONS OR
          WARRANTIES WHATSOEVER, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO
          PROFESSIONAL QUALIFICATIONS, EXPERTISE, QUALITY OF WORK OR OTHER
          INFORMATION HEREIN. FURTHER, WE DO NOT, IN ANY WAY, ENDORSE ANY
          SERVICE OFFERED OR DESCRIBED HEREIN. IN NO EVENT SHALL WE BE LIABLE TO
          YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN IN
          RELIANCE ON SUCH INFORMATION.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          THE INFORMATION PROVIDED HEREUNDER IS PROVIDED "AS IS". WE AND / OR
          OUR EMPLOYEES MAKE NO WARRANTY OR REPRESENTATION REGARDING THE
          TIMELINESS, CONTENT, SEQUENCE, ACCURACY, EFFECTIVENESS OR COMPLETENESS
          OF ANY INFORMATION OR DATA FURNISHED HEREUNDER OR THAT THE INFORMATION
          OR DATA PROVIDED HEREUNDER MAY BE RELIED UPON. MULTIPLE RESPONSES MAY
          USUALLY BE MADE AVAILABLE FROM DIFFERENT SOURCES AND IT IS LEFT TO THE
          JUDGEMENT OF USERS BASED ON THEIR SPECIFIC CIRCUMSTANCES TO USE,
          ADAPT, MODIFY OR ALTER SUGGESTIONS OR USE THEM IN CONJUNCTION WITH ANY
          OTHER SOURCES THEY MAY HAVE, THEREBY ABSOLVING US AS WELL AS OUR
          CONSULTANTS, BUSINESS ASSOCIATES, AFFILIATES, BUSINESS PARTNERS AND
          EMPLOYEES FROM ANY KIND OF PROFESSIONAL LIABILITY.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          WE SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSSES OR INJURY
          ARISING OUT OF OR RELATING TO THE INFORMATION PROVIDED ON THE
          PLATFORM. IN NO EVENT WILL WE OR OUR EMPLOYEES, AFFILIATES, AUTHORS OR
          AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR
          ACTION TAKEN BY YOUR RELIANCE ON THE CONTENT CONTAINED HEREIN.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          IN NO EVENT WILL WE BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT
          LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
          EXEMPLARY DAMAGES, DAMAGES ARISING FROM PERSONAL INJURY/WRONGFUL
          DEATH, AND DAMAGES RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS
          INTERRUPTION), RESULTING FROM ANY SERVICES PROVIDED BY ANY THIRD PARTY
          OR MERCHANT ACCESSED THROUGH THE PLATFORM, WHETHER BASED ON WARRANTY,
          CONTRACT, TORT, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT WE ARE
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <h2 className="text-xl font-bold pt-10">24. Additional Disclosures</h2>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          No waiver by either you or Venn of any breach or default or failure to
          exercise any right allowed under these Terms of Use is a waiver of any
          preceding or subsequent breach or default or a waiver or forfeiture of
          any similar or future rights under our Terms of Use. The section
          headings used herein are for convenience only and shall be of no legal
          force or effect. If a court of competent jurisdiction holds any
          provision of our Terms of Use invalid, such invalidity shall not
          affect the enforceability of any other provisions contained in these
          Terms of Use, and the remaining portions of our Terms of Use shall
          continue in full force and effect.
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          Any correspondence with Venn should be directed to: 235, 1st A Main
          Rd, Stage 3, Indiranagar, Bengaluru, Karnataka 560038
        </p>
        <p className="text-md pt-7 max-sm:text-sm max-sm:pt-4">
          The provisions of these Terms of Use apply equally to, and are for the
          benefit of, Venn, its subsidiaries, affiliates, Merchants, and its
          third-party content providers and licensors, and each shall have the
          right to assert and enforce such provisions directly.
        </p>
      </div>
    </Card>
  );
};
